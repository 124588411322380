<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useHead } from '@unhead/vue'
import LoginForm from '@/components/Forms/LoginForm/LoginForm.vue'
import HeaderVisualHome from '@/components/HeaderVisual/HeaderVisualHome.vue'
import Card from '@/components/partials/Card.vue'
import Container from '@/components/Structure/Container/Container.vue'
import DefaultLayout from '@/layouts/default.vue'
import { useLocalizedRouter } from '@/composables/localizedRouter'

const { t } = useI18n()

useHead({
  title: t('titles.login'),
})

const { localizedRoute } = useLocalizedRouter()
const router = useRouter()

async function login() {
  await router.push(localizedRoute({ name: 'customer-account' }))
}
</script>

<template>
  <DefaultLayout>
    <div class="login">
      <HeaderVisualHome overlay full home />
      <Container class="login__content">
        <Card padded>
          <LoginForm @login="login" />
        </Card>
      </container>
    </div>
  </DefaultLayout>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.login {
  &__content {
    margin-top: calc(7rem + env(safe-area-inset-top));

    @include lg-up {
      margin-top: 14rem;
    }
  }
}
</style>
